@import "custom";
@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
  // visibility: hidden;
  // &.wf-active {
  //   visibility: visible;
  // }
}
[v-cloak] {
  display: none;
}
.slick-slider {
  opacity: 0;
  transition: all 1s ease;
}
.slick-slider.slick-initialized {
  opacity: 1;
}
img {
  max-width: 100%;
  height: auto;
}

#philosophy {
  background-image: url("../img/bg_philosophy.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: $white;
}
#story {
  position: relative;
  overflow: hidden;
  .bg {
    position: absolute;
    z-index: -1;
    mix-blend-mode: multiply;
    &.bg1 {
      top: -420px;
      right: -420px;
    }
    &.bg2 {
      top: 25%;
      left: -80px;
    }
    &.bg3 {
      bottom: 0;
      right: -80px;
    }
    &.bg4 {
      bottom: 5%;
      left: 30px;
    }
  }
}
#message {
  background-color: #f7f7f7;
  position: relative;
  .brokenHeader {
    position: relative;
    z-index: 2;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    width: 100%;
    height: 20%;
    background-color: $white;
    position: absolute;
    top: 0;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      height: 25%;
    }
    @include media-breakpoint-up(lg) {
      height: 36%;
    }
  }
}
#attractive {
  position: relative;
  .bg {
    position: absolute;
    bottom: 0;
    z-index: -1;
    img {
      width: 100%;
      height: auto;
    }
  }
}
#schedule {
  background-color: #fff2f4;
}
#careerpath {
  background-color: #f3fbff;
}
#interview {
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 2;
  }
  .bg {
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;
    // z-index: -1;
    &.bg1 {
      top: 5%;
      right: -200px;
    }
    &.bg2 {
      top: 30%;
      left: -80px;
    }
    &.bg3 {
      top: 50%;
      right: 0;
    }
    &.bg4 {
      top: 60%;
      left: -230px;
    }
    &.bg5 {
      top: 90%;
      right: -30px;
    }
    &.bg6 {
      bottom: 0;
      left: 0px;
    }
  }
}
#infomation {
  background-color: #f7f7f7;
}
@import "components/header";
@import "components/hero";
@import "components/layout";
// @import "components/thumbnailLink";
@import "components/sectionheader";
@import "components/brokenHeader";
@import "components/reading";
@import "components/nav";
@import "components/cta";
@import "components/whiteBox";
// @import "components/sidemenu";
@import "components/dataTable";
@import "components/wpblockstyles";
@import "components/pageheader";
@import "components/newslist";
// @import "components/digest";
@import "components/pagination";
@import "components/cms";
@import "components/summaryTable";
@import "components/faq";
// @import "components/btn";
@import "components/icontextlink";
@import "components/iconText";
@import "components/footer";
// @import "components/animation";
@import "u";
