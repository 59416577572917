.iconTextLink {
  display: flex;
  align-items: stretch;
  width: 100%;
  border: 2px solid $body-color;
  text-decoration: none;
  color: $body-color;
  border-radius: $border-radius;
  margin-bottom: 5px;
  transition: $transition-base;
  background-color: $white;
  .icon {
    min-width: 60px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: bold;
    border-right: 2px solid $body-color;
    transition: $transition-base;
  }
  .text {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    transition: $transition-base;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  &:hover {
    border: 2px solid $primary;
    background-color: $primary;
    color: $white;
    .icon {
      border-right: 2px solid $white;
    }
  }
}
