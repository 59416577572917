@use "sass:math";

$headerHeightSM: 80px;
$headerHeightMD: 90px;
$headerHeightLG: 100px;
#header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $headerHeightSM;
  padding: 0 15px;
  @include media-breakpoint-up(sm) {
    padding: 0 20px;
    height: $headerHeightMD;
  }
  @include media-breakpoint-up(lg) {
    height: $headerHeightLG;
  }
  .logo {
    padding-right: 15px;
    border-right: 1px solid $gray-200;
    @include media-breakpoint-up(lg) {
      padding-right: 20px;
    }
    img {
      height: 36px;
      width: auto;
      @include media-breakpoint-up(lg) {
        height: 50px;
      }
    }
  }
  .logoright {
    padding-left: 12px;
    @include media-breakpoint-up(lg) {
      padding-left: 20px;
    }
    img {
      height: 30px;
      width: auto;
      @include media-breakpoint-up(lg) {
        height: 45px;
      }
    }
  }
}
#btnEntry {
  width: $headerHeightSM;
  height: $headerHeightSM;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $danger;
  color: $white;
  font-size: 13px;
  font-family: $font-family-en;
  font-weight: bold;
  text-decoration: none;
  transition: $transition-base;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  &.slickLoaded {
    opacity: 1;
  }
  @include media-breakpoint-up(sm) {
    width: $headerHeightMD;
    height: $headerHeightMD;
    font-size: 15px;
  }
  @include media-breakpoint-up(lg) {
    width: $headerHeightLG;
    height: $headerHeightLG;
  }
  &::after {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    font-family: "Font Awesome 5 Pro";
    content: "\f2d2";
    opacity: 0.75;
  }
  &:hover {
    background-color: darken($danger, 5%);
  }
}
#toggleMenuBtn {
  width: $headerHeightSM;
  height: $headerHeightSM;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: $body-color;
  font-size: 13px;
  font-family: $font-family-en;
  font-weight: bold;
  text-decoration: none;
  transition: $transition-base;
  position: fixed;
  z-index: $zindex-fixed;
  top: $headerHeightSM;
  right: 0;
  cursor: pointer;
  opacity: 0;
  &.slickLoaded {
    opacity: 1;
  }
  div {
    width: math.div($headerHeightSM, 2.5);
    height: 1px;
    background-color: $body-color;
    &.bar2 {
      margin: 10px 0;
    }
  }
  .bar1,
  .bar3 {
    transition: $transition-base;
  }
  &:hover {
    background-color: $gray-200;
    .bar1,
    .bar3 {
      transform: scaleX(0.75);
      transform-origin: right;
    }
  }
  @include media-breakpoint-up(sm) {
    width: $headerHeightMD;
    height: $headerHeightMD;
    top: $headerHeightMD;
  }
  @include media-breakpoint-up(lg) {
    width: $headerHeightLG;
    height: $headerHeightLG;
    top: $headerHeightLG;
  }
}
