.cta {
  padding: 45px 0;
  background: linear-gradient(90deg, #fa7f97, #c42444);
  text-align: center;
  @include media-breakpoint-up(sm) {
    padding: 60px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 90px 0;
  }
  .title {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    font-family: $font-family-en;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: $white;
    margin-bottom: 24px;
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 74px;
    }
  }
}
.btn-cta {
  color: $danger;
  width: 320px;
  padding: 18px;
  font-weight: bold;
  background-color: $white;
  border: 1px solid $white;
  &:hover {
    background-color: $danger;
    color: $white;
  }
}
