.pageHeader {
  position: relative;
  width: 100%;
  background-color: $body-color;
  color: $white;
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  .data {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-right: 1rem;
        a {
          padding: 5px 20px;
          background-color: $gray-200;
          color: $body-color;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            color: $gray-800;
          }
        }
      }
    }
    .date {
      font-weight: bold;
    }
  }
  .title {
    margin: 0;
    line-height: 1.2;
    font-weight: bold;
    font-size: 21px;
    @include media-breakpoint-up(sm) {
      font-size: 27px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
}
