.wp-block-image img {
  height: auto;
}
.wp-block-button__link {
  font-size: 90% !important;
}
.wp-block-latest-posts__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-top: 1px solid $gray-200;
    padding: 15px 0;
    &:last-child {
      border-bottom: 1px solid $gray-200;
    }
    a {
      text-decoration: none;
      color: $body-color;
      margin-bottom: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
