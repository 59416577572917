.iconTextWrap {
  .iconText {
    background-color: $primary;
    color: $white;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 80px;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      min-height: 90px;
      margin-bottom: 15px;
    }
    &:nth-child(even) {
      background-color: rgba($primary, 0.8);
    }
    .icon {
      width: 80px;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-en;
      font-weight: bold;
      font-size: 36px;
      @include media-breakpoint-up(lg) {
        width: 150px;
        min-height: 90px;
        font-size: 66px;
      }
    }
    .text {
      flex: 1;
      min-height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      padding: 15px;
      @include media-breakpoint-up(lg) {
        min-height: 90px;
        font-size: 24px;
      }
    }
  }
}
