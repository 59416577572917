.sectionHeader {
  text-align: center;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
  .subtitle {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    letter-spacing: 0.1em;
    color: $body-color;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
      margin-top: 15px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }
  .title {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    font-family: $font-family-en;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: $primary;
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 74px;
    }
  }
}
.harf {
  .sectionHeader {
    .subtitle {
      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
    }
    .title {
      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }
    }
  }
}
