.reading {
  h3 {
    color: $primary;
    margin-bottom: 60px;
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }
  p {
    line-height: 2.5;
    font-size: 13px;
    margin-bottom: 60px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }
  }
}
