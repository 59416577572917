.cms {
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: $primary;
    position: relative;
    border-bottom: 1px solid $gray-200;
    padding-bottom: 15px;
    &::after {
      content: "";
      width: 45px;
      height: 1px;
      background-color: $primary;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  p {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
}
