.whiteBox {
  background-color: $white;
  border-radius: 15px;
  box-shadow: $box-shadow;
  position: relative;
  .brIcon {
    font-size: 110px;
    line-height: 1;
    color: $gray-100;
    font-family: $font-family-en;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: 1;
  }
  .whiteBoxHeader {
    position: relative;
    z-index: 2;
    .icon {
      width: 100%;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 70px;
        width: auto;
      }
    }
    .title {
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      color: $primary;
      line-height: 1.5;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
    }
  }
  .whiteBoxBody {
    position: relative;
    z-index: 2;
    padding: 15px;
    padding-top: 0;
    @include media-breakpoint-up(sm) {
      padding: 30px;
      padding-top: 0;
    }
  }
  .profile {
    padding: 30px;
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }
    @include media-breakpoint-up(lg) {
      padding: 60px;
    }
    .img {
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        width: 40%;
        margin-right: 30px;
        margin-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        margin-right: 45px;
      }
    }
    .cont {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      .name {
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 18px;
        @include media-breakpoint-up(sm) {
          font-size: 21px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 28px;
        }
      }
      .name_en {
        font-size: 12px;
        color: $primary;
        font-family: $font-family-en;
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
          font-size: 14px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 15px;
        }
      }
    }
  }
  .cms {
    padding: 30px;
    padding-top: 0;
    @include media-breakpoint-up(lg) {
      padding: 60px;
      padding-top: 0;
    }
  }
}
.whiteBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
  .whiteBox {
    flex: 1;
    @include media-breakpoint-up(lg) {
      margin: 0 10px;
    }
  }
}
