@use "sass:math";

.hero {
  position: relative;
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: $primary;
    transition: all 2s cubic-bezier(0, 0.71, 0.55, 1.04);
  }
  &.slickLoaded {
    &::after {
      width: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row-reverse;
    margin-top: math.div($headerHeightMD, -1);
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
    margin-top: math.div($headerHeightLG, -1);
  }
  .img {
    @include media-breakpoint-up(sm) {
      background-color: $primary;
    }
  }
  .cont {
    padding-top: $headerHeightSM * 0.5;
    padding-bottom: $headerHeightSM;
    text-align: center;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(sm) {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
    }
    .subcopy {
      font-size: 14px;
      letter-spacing: 0.2em;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    .catchcopy {
      font-size: 24px;
      letter-spacing: 0.2em;
      font-weight: bold;
      color: $primary;
      line-height: 1.5;
      margin: 0;
      @include media-breakpoint-up(lg) {
        font-size: 42px;
      }
    }
    .scrollicon {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      font-size: 10px;
      line-height: 1;
      font-family: $font-family-en;
      font-weight: bold;
      color: $primary;
      @include media-breakpoint-up(lg) {
        bottom: 45px;
      }
      &::after {
        margin-top: 2px;
        color: $primary;
        font-size: 10px;
        font-family: "Font Awesome 5 Pro";
        content: "\f078";
        animation: scrollanime 1s ease-out infinite alternate;
      }
    }
    .bg {
      position: absolute;
      bottom: -2px;
      width: 100%;
      vertical-align: bottom;
      height: auto;
    }
  }
  .img {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 60%;
    }
  }
}
@keyframes scrollanime {
  0% {
    transform: translateY(0);
    // opacity: 1;
  }
  100% {
    transform: translateY(5px);
    // opacity: 0;
  }
}
.heroSlideSingle {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    height: 50vh;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
  &::before {
    content: "";
    width: 100%;
    padding-top: 88%; //高さの比率 ÷ 幅の比率 × 100
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 8s linear;
    @include media-breakpoint-up(sm) {
      position: relative;
    }
  }
  .bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: $gray-200;
    @include media-breakpoint-up(sm) {
      height: 6px;
    }
    &::before {
      content: "";
      background-color: $success;
      width: 0;
      height: 100%;
      position: absolute;
      transition: 4s linear;
    }
  }
}
.slick-active {
  .heroSlideSingle {
    img {
      transform: scale(1.1);
    }
    .bar {
      &::before {
        width: 100%;
      }
    }
  }
}
