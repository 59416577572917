.brokenHeader {
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }
  .title {
    font-size: calc(100vw / 5.5);
    text-shadow: $primary 1px 1px 0, $primary -1px -1px 0,
      /*右下、左上*/ $primary -1px 1px 0, $primary 1px -1px 0,
      /*右上、左下*/ $primary 0px 1px 0, $primary 0-1px 0,
      /*右、左*/ $primary -1px 0 0, $primary 1px 0 0; /*上、下*/
    color: $white;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: calc(100vw / 5);
    }
    @include media-breakpoint-up(lg) {
      // font-size: calc(100vw / 4.75);
      text-align: center;
    }
  }
  .img {
    text-align: right;
    margin-top: -15px;
    img {
      width: 75%;
      height: auto;
    }
    @include media-breakpoint-up(sm) {
      margin-top: -36px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -5%;
      padding-right: 6%;
      img {
        width: 60%;
      }
    }
  }
}
