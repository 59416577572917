.faq {
  .q {
    background-color: rgba($primary, 0.05);
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 60px;
    @include media-breakpoint-up(lg) {
      min-height: 70px;
    }
    .icon {
      width: 60px;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-en;
      font-weight: bold;
      font-size: 24px;
      color: $primary;
      @include media-breakpoint-up(lg) {
        width: 150px;
        min-height: 70px;
        font-size: 40px;
      }
    }
    .text {
      flex: 1;
      min-height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      padding: 15px;
      color: $primary;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        font-size: 21px;
      }
    }
  }
  .a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 60px;
    padding-bottom: 30px;
    .icon {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-en;
      font-weight: bold;
      font-size: 24px;
      color: $warning;
      padding: 15px;
      @include media-breakpoint-up(lg) {
        width: 150px;
        font-size: 40px;
        padding: 30px 15px;
      }
    }
    .text {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      padding: 15px;
      @include media-breakpoint-up(lg) {
        font-size: 15px;
        padding: 30px 15px;
      }
    }
  }
}
