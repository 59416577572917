.summaryTable {
  display: table;
  border-top: 1px solid $gray-200;
  font-size: 12px;
  width: 100%;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  dl {
    display: table-row;
    dt {
      display: table-cell;
      font-weight: bold;
      padding: 15px 0;
      width: 1%;
      white-space: nowrap;
      border-bottom: 1px solid $gray-200;
    }
    dd {
      display: table-cell;
      padding: 15px 0;
      padding-left: 15px;
      border-bottom: 1px solid $gray-200;
    }
  }
}
