.newslist {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-top: 1px solid $gray-200;
    &:last-child {
      border-bottom: 1px solid $gray-200;
    }
    a {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      padding: 15px 0;
      transition: $transition-base;
      text-decoration: none;
      color: $body-color;
      &:hover {
        background-color: $gray-100;
      }
      .date {
        margin-right: 15px;
      }
      .title {
        flex: 1;
      }
    }
  }
}
