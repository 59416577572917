#footer {
  background-color: #333333;
  padding: 90px 0;
  @include media-breakpoint-up(sm) {
    padding: 120px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 180px 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .footerHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .logo {
        img {
          height: 50px;
          width: auto;
        }
      }
      .copyright {
        color: $gray-300;
        font-size: 10px;
        margin-top: 15px;
      }
    }
    .footerBody {
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
        width: 55%;
        font-size: 12px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 15px;
      }
    }
  }
}
.fnav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $gray-300;
      text-decoration: none;
    }
  }
}
