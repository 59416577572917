#toggleMenu {
  position: fixed;
  z-index: $zindex-fixed - 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $gray-100;
  padding: 30px;
  overflow: auto;
  @include media-breakpoint-up(sm) {
    padding: 60px;
  }
  @include media-breakpoint-up(lg) {
    padding: 90px;
  }
}
.gnav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  li {
    @include media-breakpoint-up(sm) {
      flex-basis: 33.333%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 25%;
    }
    a {
      display: block;
      text-decoration: none;
      padding: 10px;
      transition: $transition-base;
      &:hover {
        background-color: $white;
      }
      .ja {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
        color: $body-color;
        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }
      }
      .en {
        font-size: 10px;
        font-weight: bold;
        font-family: $font-family-en;
        margin: 0;
        color: $primary;
        @include media-breakpoint-up(sm) {
          font-size: 12px;
        }
      }
    }
  }
}
