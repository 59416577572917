// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Poppins:ital,wght@0,500;1,600&display=swap");
$font-family-sans-serif: "Noto Sans JP", sans-serif;
$font-family-en: "Poppins", sans-serif;

$headings-font-weight: bold;

// scss-docs-start color-variables
$blue: #1f67d0;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
$pink: #f03c5f;
// $red: #dc3545 !default;
// $orange: #fd7e14 !default;
$yellow: #e0e400;
// $green: #198754 !default;
$teal: #a8ce05;
// $cyan: #0dcaf0 !default;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

// scss-docs-start theme-color-variables
$primary: $blue;
// $secondary: $gray-600 !default;
$success: $teal;
// $info: $cyan !default;
$warning: $yellow;
$danger: $pink;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$btn-padding-y-lg: 17px;
$btn-padding-x-lg: 17px;
$btn-font-size-lg: $input-btn-font-size;

// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
